.App {
  text-align: center;

}

.box {
  background-position: center;

  height: 400px;
  width: auto;
  background-color: rgb(43, 128, 247);
}

.btn {
  position: relative;
  top: 20px;

}

.footer {
  position: relative;
  border-bottom: 1px solid black;
  top: 16px;
  opacity: 0.6;
}

.paragraf {
  padding: 18px;
  text-wrap: wrap;
  color: white;
}

.title {
  color: white;
  text-wrap: wrap;
  margin: 5px;
  padding: 2px;
  ;
}

.link {
  color: white;
  font-size: 25px;
}

.link1 {
  color: white;
  padding: 8px;
}

.h3 {
  padding: 20px;
}
#blink_text {
  animation-name: blink;
  
  animation-duration: 2s;
  animation-timing-function: ease-in;
  animation-iteration-count: Infinite;
}

@keyframes blink {
  0% {
    color: red;
  }

  50% {
    color: white;
  }

  100% {
    color: red;
  }
}